import { buildImageObj, cn, getBlogUrl } from '../libs/helpers';
import { Link } from 'gatsby';
import PortableText from './portableText';
import React from 'react';
import { format } from 'date-fns';
import { imageUrlFor } from '../libs/image-url';

//erzeugt Link zum jeweiligen Blogpost auf Blog Seite

function BlogPostPreview(props) {
  return (
    <Link
      className="link"
      to={getBlogUrl(props.publishedAt, props.slug.current)}
    >
      <div className="leadMediaThumb">
        {props.mainImage && props.mainImage.asset && (
          <img
            src={imageUrlFor(buildImageObj(props.mainImage))
              .width(600)
              .height(Math.floor((9 / 16) * 600))
              .auto('format')
              .url()}
            alt={props.mainImage.alt}
          />
        )}
      </div>
      <div className="text">
        <h3 className="title">{props.title}</h3>
        {props.excerpt && (
          <div className="excerpt">
            {/* <PortableText blocks={props.excerpt} /> */}
            {props.excerpt}
          </div>
        )}
        <div className="date">
          {format(new Date(props.publishedAt), 'MMMM Mo, yyyy')}
        </div>
      </div>
    </Link>
  );
}

export default BlogPostPreview;
