import BlogPostPreviewGrid from '../templates/blog-post-preview-grid';
import BlogContainer from '../components/BlogContainer';
import BlogMain from '../components/BlogMain';
import BlogFeatureImage from '../components/BlogFeatureImage';
import BlogCard from '../components/BlogCard';

import GraphQLErrorList from '../components/graphql-error-list';

import React from 'react';
import SEO from '../components/Seo';
import { graphql } from 'gatsby';
import { mapEdgesToNodes } from '../libs/helpers';

export const query = graphql`
  query ArchivePageQuery {
    posts: allSanityPost(
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          id
          publishedAt
          title
          excerpt
          slug {
            current
          }
        }
      }
    }
  }
`;

const ArchivePage = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <div>
        <GraphQLErrorList errors={errors} />
      </div>
    );
  }

  const postNodes = data && data.posts && mapEdgesToNodes(data.posts);

  return (
    <div>
      <SEO title="Archive" />
      <div>
        <BlogContainer>
          <BlogFeatureImage>Bild Blog Start</BlogFeatureImage>
          <BlogMain>
            <BlogCard>
              <h1 className="responsiveTitle1">Archive</h1>
              {postNodes && postNodes.length > 0 && (
                <BlogPostPreviewGrid nodes={postNodes} />
              )}
            </BlogCard>
          </BlogMain>
        </BlogContainer>
      </div>
    </div>
  );
};

export default ArchivePage;
